import * as React from 'react'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  useDelayGroup,
  useDelayGroupContext,
  useMergeRefs,
  useId,
  useTransitionStyles,
  type Placement,
} from '@floating-ui/react'

import { cx } from '@/utils/strings'

interface TooltipOptions {
  initialOpen?: boolean
  placement?: Placement
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export function useTooltip({
  initialOpen = false,
  placement = 'top',
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}: TooltipOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen)

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const { delay } = useDelayGroupContext()

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(0), flip(), shift()],
  })

  const { context } = data

  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
    delay,
  })
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const interactions = useInteractions([hover, focus, dismiss, role])

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data]
  )
}

type ContextType = ReturnType<typeof useTooltip> | null

const TooltipContext = React.createContext<ContextType>(null)

export const useTooltipState = () => {
  const context = React.useContext(TooltipContext)

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />')
  }

  return context
}

export function BwTableTooltip({ children, ...options }: { children: React.ReactNode } & TooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options)
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>
}

export const BwTableTooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(({ children, asChild = false, ...props }, propRef) => {
  const state = useTooltipState()

  const childrenRef = (children as any)?.ref
  const ref = useMergeRefs([state.refs.setReference, propRef, childrenRef])

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      state.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': state.open ? 'open' : 'closed',
      })
    )
  }

  return (
    <button
      type="button"
      ref={ref}
      // The user can style the trigger based on the state
      data-state={state.open ? 'open' : 'closed'}
      {...state.getReferenceProps(props)}
      className={cx(
        'text-left lg:absolute lg:left-0 lg:right-0 lg:top-0 lg:bottom-0 cursor-default hover:bg-gray-100 z-[1]',
        props.className
      )}
    >
      <span className="block lg:px-10 leading-tight">{children}</span>
    </button>
  )
})

export const BwTableTooltipContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  ({ className, ...props }, propRef) => {
    const state = useTooltipState()
    const id = useId()
    const { isInstantPhase, currentId } = useDelayGroupContext()
    const ref = useMergeRefs([state.refs.setFloating, propRef])

    useDelayGroup(state.context, { id })

    const instantDuration = 0
    const duration = 250

    const { isMounted, styles } = useTransitionStyles(state.context, {
      duration: isInstantPhase
        ? {
            open: instantDuration,
            // `id` is this component's `id`
            // `currentId` is the current group's `id`
            close: currentId === id ? duration : instantDuration,
          }
        : duration,
      initial: {
        opacity: 0,
      },
    })

    if (!isMounted) return null

    return (
      <FloatingPortal>
        <div
          className={cx(
            'w-[300px] shadow-sm lg:w-[430px] py-4 px-4 rounded-2xl text-white bg-indigoBlue z-10',
            className
          )}
          ref={ref}
          style={{
            ...state.floatingStyles,
            ...props.style,
            ...styles,
          }}
          {...state.getFloatingProps(props)}
        />
      </FloatingPortal>
    )
  }
)
